import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Flash from './Loader';

export default function Skeleton({ page }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1000);
  }, []);

  return (
    <div>
      {!show && <Flash />}
      {page}
    </div>
  );
}

Skeleton.propTypes = {
  page: PropTypes.any.isRequired,
};
