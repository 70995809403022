/* eslint-disable react/jsx-props-no-spreading */
import { Store, LandingPage } from 'containers/lazy';
import { useIsCustomDomain } from 'contexts/profile';
import Skeleton from 'components/layouts/Skeleton';

export default function Home(props) {

  const isCustomDomain = useIsCustomDomain();
  if (isCustomDomain) {

    return (
      <Store {...props} />
    );

  }
  return (
    <LandingPage />
  );

}

Home.getLayout = (page) => <Skeleton page={page}/>;

Home.propTypes = {
};

Home.defaultProps = {
};

export const getServerSideProps = async (ctx) => {

  const { getStoreProps } = require('containers/api/store');
  const { isCustomDomain } = require('server/lib/domain');
  const { host } = ctx.req.headers;
  const customDomain = isCustomDomain(host);
  if (customDomain) {
    return getStoreProps(ctx);
  }

  return {
    props:{}
  }
};
