import React, {Fragment} from 'react';
import Loader from '@mui/material/Skeleton';
import { Grid } from '@mui/material';
import styles from './Loader.module.css';

export default function Flash() {

  const arr3 = [0,1,2];
  const arr2 = [0,1];

  return (<div className={styles.container}>
    <Loader animation={'wave'} variant={'rect'} width={'100%'} height={'60px'}/>
    <div className={styles.main}>
      {arr3.map((i) => (
        <Fragment key={i}>
          <Grid container spacing={2}>
            {arr2.map(j=>(
              <Grid item xs={6} key={j}>
                <div className={styles.flashCard}>
                  <div className={styles.second}>
                    <div>
                      <Loader animation={'wave'} variant={'circle'} height={'65px'} width={'65px'}/>
                    </div>        
                    <div className={styles.first}>
                      <Loader animation={'wave'} variant={'text'} />
                      <Loader animation={'wave'} variant={'text'} width={'80%'} />
                    </div>
                  </div>
                  <div className={styles.third}>
                    <Loader animation={'wave'} variant={'rect'} width={'100%'} height={'20vh'}/>
                    <div className={styles.padding}/>
                    <Loader animation={'wave'} variant={'text'} />
                    <Loader animation={'wave'} variant={'text'} width={'70%'}/>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
          <div className={styles.padding}/>
          <Loader animation={'wave'} variant={'text'} width={'100%'} height={'4px'} />
          <div className={styles.padding}/>
        </Fragment>
      ))}
    </div>
    <Loader animation={'wave'} variant={'rect'} width={'100%'} height={'30px'}/>
  </div>
  );
} 
